//modules
@import './app/shared/styles/themes/material-custom-theme.scss';
@import './app/shared/styles/modules/utility.scss';
@import './app/shared/styles/modules/material.scss';
@import './app/shared/styles/modules/global.scss';
@import './app/shared/styles/modules/icons.scss';

//partials
@import './app/shared/styles/partials/material-checkbox-aligned-right.scss';
@import './app/shared/styles/partials/material-radio-aligned-right.scss';
@import './app/shared/styles/partials/custom-leaflet.scss';
@import './app/shared/styles/partials/passenger-selection.scss';
@import './app/shared/styles/partials/custom-tel-input.scss';
@import './app/shared/styles/partials/custom-bootstrap-tooltip.scss';
@import './app/shared/styles/partials/install-prompt.scss';
@import './app/shared/styles/partials/share-button-custom.scss';
@import './app/shared/styles/partials/modal-custom.scss';
@import './app/shared/styles/partials/custom-tab.scss';
@import './app/shared/styles/partials/custom-tabs.scss';
@import './app/shared/styles/partials/custom-form-grid-system.scss';
@import './app/shared/styles/partials/autocomplete-location.scss';

//primeng
@import './app/shared/styles/partials/_primeng.inputtext.scss';
@import './app/shared/styles/partials/_primeng.multiselect.scss';
@import './app/shared/styles/partials/_primeng.dropdown.scss';
@import './app/shared/styles/partials/_primeng.autocomplete.scss';
@import './app/shared/styles/partials/_primeng.terminal.scss';
@import './app/shared/styles/partials/_primeng.slider.scss';

//components
@import './app/shared/styles/components/daterangepicker-options.scss';
@import './app/shared/styles/components/train-logo.scss';
@import './app/shared/styles/components/mat-stepper-timeline.scss';
@import './app/shared/styles/components/custom-form-tabs.scss';
@import './app/shared/styles/components/timepicker-bootstrap.scss';
@import './app/shared/styles/components/buttons.scss';

//dependencies
@import 'intl-tel-input/build/css/intlTelInput.css';

/* Sweet alert */
.swal-footer {
	text-align: center;
}

app-root {
	display: flex;
	flex: 1;
	flex-direction: column;
}
app-root > ng-component {
	display: flex;
	flex: 1;
	flex-direction: column;
}

subscription-notification-bar,
active-delegated-users-combo,
quick-theme-selection,
language-switch-dropdown,
header-notifications,
chat-toggle-button,
user-menu {
	display: flex;
}

#notificationIcon,
#chatIconUnRead {
	position: relative;
}

.theme-selection-link {
	cursor: pointer;
}

.dropdown-language .navi-link i.famfamfam-flags {
	display: inline-block;
}

header-notifications .dropdown-menu {
	max-width: 400px;
}

body.aside-minimize #kt_brand {
	width: 70px;
}

.cc-window {
	background-color: #282a3a;
}
.cc-message {
	color: #fefefe;
}
.cc-btn {
	background-color: #5867dd;
	border-color: #5867dd;
	color: #fff;
}
.cc-btn {
	display: block;
	padding: 0.4em 0.8em;
	font-size: 0.9em;
	font-weight: 700;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap;
}
.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
	cursor: pointer;
}

/* Editing general CSS metronic */

.checkbox-list .checkbox:last-child {
	margin-bottom: 1rem !important;
}
.goldColor {
	color: #fce305 !important;
}
.btn i {
	// padding-right: 0 !important;
	margin-right: 0.35rem !important;
}
.btn.btn-icon i {
	padding: 0 !important;
	margin: 0 !important;
}
.mat-option {
	padding: 0 16px !important;
}
body .primeng-datatable-container .primeng-paging-container .ui-paginator .ui-dropdown-label-container span {
	padding: 0.429em;
}
.sliderContainer {
	display: flex;
	align-items: center;
}
body .font-weight-bold {
	font-weight: bold !important;
}
body .modal .modal-header .close span {
	display: block;
}
body .modal .modal-header .close i,
body .modal .modal-header .close .ki {
	font-size: 1.25rem !important;
}
/* Schermi grandi o ad alta risoluzione
@media all and (max-width: 1690px) { ... }

/* Schermi Desktop o Portatili
@media all and (min-width: 1280px) and (max-width: 1689px) { ... }

/* Tablet in modalità orizzontale
@media all and (min-width: 737px) and (max-width: 1279px) { ... }

/* Tablet in modalità verticale
@media all and (min-width: 481px) and (max-width: 736px) { ... }

/* Smartphone o piccoli Tablet  */
@media (max-width: 576px) {
	.card-body {
		padding: 1rem 0.75rem !important;
	}
	.container-fluid {
		padding: 0 0.5rem !important;
	}
	.row {
		margin-right: 0 !important;
		margin-left: 0 !important;
	}
	.noMarginLateralOnMobile {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.noPaddingLateralOnMobile {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.lessMarginLateralOnMobile {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}
	.lessPaddingLateralOnMobile {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.card-body {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.row {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.dropdown-menu.dropdown-menu-sm {
		width: 90vw !important;
		right: auto !important;
		left: auto !important;
		transform: translate(-52%);
	}
}
.success-snackbar {
	color: white !important;
	background-color: #1bc5bd !important;
	.mat-simple-snackbar-action {
		color: white !important;
	}
}

.iub_content {
	padding-top: 0px !important;
}

.dialog-no-padding > mat-dialog-container {
	padding: 0px;
}
