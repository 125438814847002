.daterangepicker {
    td.off.available {
        visibility: hidden;
        height: 0;
    }
}

.bootstrap-datetimepicker-widget {
    .day.new, .day.old {
        visibility: hidden;
        height: 0;
    }
}

/*ngx bootstrap date picker primary*/
.theme-primary .bs-datepicker-head {
    background-color: var(--primary);
    button {
        color: var(--gray) !important;
    } 
}
.bs-datepicker-head {
    button {
        color: var(--white) !important;
    } 
}
bs-daterangepicker-container button {
    color: var(--gray) !important;
}

  .theme-primary .bs-datepicker-body table td span.selected,
  .theme-primary .bs-datepicker-body table td.selected span,
  .theme-primary .bs-datepicker-body table td span[class*="select-"]:after,
  .theme-primary .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: var(--gray); }

  .theme-primary .bs-datepicker-body table td.week span {
    color: var(--primary); }



.bs-datepicker-body table td span.selected, .bs-datepicker-body table td.selected span {
    background-color: var(--gray) !important;
}