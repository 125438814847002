body .ui-slider .ui-slider-handle, body .ui-slider .ui-slider-range {
    background-color: var(--primary);
    border: 1.5px solid var(--primary);
}

body .ui-slider .ui-slider-handle:hover {
    background-color: var(--primary);
    border: 1.5px solid var(--primary) !important;
}
body .ui-slider .ui-slider-range:hover {
    background-color: var(--primary);
}

body .ui-slider.ui-slider-horizontal .ui-slider-handle {
    background-color: var(--light);

    &:hover {
        background-color: var(--primary);
    }

    &:focus {
        outline: unset;
    }
}

body .ui-slider-horizontal {
    height: .4em;
}

body .ui-slider.ui-corner-all {
    border-radius: 0;
}
