body .ui-multiselect {
    display: flex;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

body .ui-multiselect:not(.ui-state-disabled):hover {
    border-color: #9aabff;
}

body .ui-multiselect.ui-widget {
    font-size: 1rem;
    font-weight: 400;
}


body .ui-corner-all {
    border-radius: 4px;
    border: 1px solid #e2e5ec;
}

body .ui-corner-all:enabled:focus:not(.ui-state-error) {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
}

body .ui-corner-all:enabled:hover:not(.ui-state-error) {
    border-color: #9aabff;
}



body .ui-widget {
    font-family: "Poppins", "Roboto";
}
