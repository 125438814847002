
body input.ui-inputtext {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Poppins", "Roboto";
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

body .ui-inputtext::-ms-expand {
    background-color: transparent;
    border: 0;
}

body .ui-inputtext:enabled:focus:not(.ui-state-error) {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
}

body .ui-inputtext::-webkit-input-placeholder {
    color: #74788d;
    opacity: 1;
}

body .ui-inputtext::-moz-placeholder {
    color: #74788d;
    opacity: 1;
}

body .ui-inputtext:-ms-input-placeholder {
    color: #74788d;
    opacity: 1;
}

body .ui-inputtext::-ms-input-placeholder {
    color: #74788d;
    opacity: 1;
}

body .ui-inputtext::placeholder {
    color: #74788d;
    opacity: 1;
}

body .ui-inputtext:disabled, body .ui-inputtext[readonly] {
    background-color: #f7f8fa;
    opacity: 1;
}

body .ui-inputtext:enabled:hover:not(.ui-state-error) {
    border-color: #9aabff;
}
