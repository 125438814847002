@import './colors.scss';

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}

.WrapButtonFlex {
    display: flex;

    button {
        margin-right: 30px;
    }
}

.DivLRContainer {
    height: 40px;
}

.DivFLeft {
    float: left;
}

.DivFRight {
    float: right;
}

.numberColumn {
    text-align: right;
}

.textBold {
    font-weight: bold;
}

.btnNearControl {
    margin-top: 25px;
}

.ContainerFlex {
    display: flex;
}

.m-portlet {
    label {
        font-weight: 500;
    }
}

span.badge {
    padding: 5px;
    font-size: 12px;
}

.mat-optgroup-label {
    color: var(--primary);
    line-height: unset !important;
    height: auto !important;
    padding: 10px 16px !important;
    margin: 0;
}

.mat-autocomplete-panel {
    &.original-hotel-autocomplete {
        padding: 5px;
    }
}

@mixin fontAwesomeUnicode() {
    font-family: 'Font Awesome 5 Free';
    padding-right: 10px;
    font-weight: 900;
}

mat-optgroup {
    &.flight .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f072';
    }

    &.train .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f238';
    }

    &.structure .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f1ad';
    }

    &.structure .mat-optgroup-label {
        border-top: none;
        border-bottom: 1px solid var(--primary);
    }

    &.poi .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f041';
    }

    &.poi .mat-optgroup-label {
        border-top: none;
        border-bottom: 1px solid var(--primary);
    }

    &.city .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f64f';
    }

    &.city .mat-optgroup-label {
        border-top: none;
        border-bottom: 1px solid var(--primary);
    }

    &.user .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f007';
    }

    &.group .mat-optgroup-label:before {
        @include fontAwesomeUnicode();
        content: '\f0c0';
    }
}

.validation-error-text {
    color: #f4516c;
    font-size: 0.85rem;
}

.overflow-hidden {
    overflow: hidden;
}

body .ui-multiselect {
    padding: 0 0.75em;
}

.popover {
    max-width: 576px;
    min-width: 320px;
}

.row.equal {
    display: flex;
    flex-wrap: wrap;
}

.list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.wl-item {
    position: absolute;
    top: 0px;
    right: 5px;
    padding: 5px 5px 5px 5px;
}

.text-small {
    font-size: 12px;
}

.passengerOptionTextContainer {
    display: flex;
    align-items: center;
    padding: 0 7px 7px 0;
    .passengerInfoText {
        display: flex;
        flex-direction: column;
        line-height: normal;
        white-space: pre-line;
        width: 75%;
        overflow: hidden;
        .passengerInfoText_type {
            white-space: pre-line;
            font-size: 0.7em;
            @media only screen and (max-width: 600px) {
                font-size: 0.6em;
            }
        }
    }

    .passengerCodeText {
        display: flex;
        justify-content: flex-end;
        width: 25%;
        font-size: 0.9em;
        color: lighten($color: #575962, $amount: 20%);
    }
}

.mat-option.padding-option {
    padding: 10px 0px 4px 5px !important;
}
