.mat-checkbox-label {
    display: flex;
    align-items: center;
}

.mat-button-no-outline.mat-icon-button{
    min-width: unset;
    outline: unset;
}

.mat-horizontal-stepper-container-visible-y .mat-horizontal-content-container {
    overflow: visible !important;
    //overflow-x: hidden !important;
    //overflow-y: visible !important;
}