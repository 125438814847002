@mixin customScrollbar() {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        border-radius: 10px;
    }

    ::-webkit-scrollbar {
        width: 8px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--primary);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }
}
