// Questo partial è specifico per il cruscotto dei voli

.form-grid-row {
    justify-content: flex-start;
    flex-direction: column;
    display: flex;
    padding: 0;
    margin-bottom: 1rem;

    .swap,
    .datepicker-space {
        display: none;
    }

    .origin,
    .destination,
    .departureDatePicker,
    .returnDatePicker {
        width: 100%;
        margin: 0;
        padding-right: 15px;
        padding-left: 15px;
    }

    @media (min-width: 576px) {
        flex-direction: row;
        justify-content: space-between;
        .origin,
        .destination,
        .departureDatePicker,
        .returnDatePicker {
            width: 48%;
        }

        .swap,
        .datepicker-space {
            width: 4%;
            display: flex;
        }

        .origin,
        .departureDatePicker {
            padding-right: 0;
        }

        .destination,
        .returnDatePicker {
            padding-left: 0;
        }
    }
}
