// MatRadio with text aligned to right

mat-radio-button, .mat-radio-label  {
    width: 100%;
}

.mat-radio-label-content {
    width: 100%;

    span.text-aligned-right {
        float: right;
    }
}
