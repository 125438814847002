.search-flight-pax {
    min-width: 200px;
    right: 0 !important;
    left: auto;

    .search-flight-pax-block {
        display: inline-block;
    }

    .search-flight-pax-span {
        display: inline-block;
        margin-top: -5px;
    }
}

.dropdown-container {
    width: 100%;
    .m-dropdown__body {
        overflow: visible;
    }
}

.paxBox {
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
}

@media (max-width: 767.98px) {
    .search-flight-pax-span {
        text-align: center;
    }
}

.passengerSelection, .sliderToggleBox {
    width: 100%;
    @media (min-width: 576px) {
        width: 48%;
    }
}

.modal-body {
    .passengerSelection, .sliderToggleBox {
        width: 100%;
        @media (min-width: 992px) {
            width: 48%;
        }
    }
}

.passengerSelection {
    margin-right: auto;
}

.sliderToggleBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}

@media (min-width: 992px) {
    .passenger-dropdown {
        right: unset !important;
    }
}
