.mat-stepper-timeline {
    .mat-vertical-stepper-content {
        &.open {
            visibility: visible !important;
            height: auto !important;
        }
    }

    mat-step-header {
        &.transparent-hover:hover {
            background-color: inherit !important;
        }
    }

    @media(max-width: 576px) {
        mat-step-header, .mat-vertical-content {
            padding: 0;
            padding-left: 20px;
        }

        .mat-step-header {
            height: unset;
        }

        &.mat-stepper-timeline--responsive {

            .mat-stepper-vertical-line::before {
                content: unset;
            }

            .mat-vertical-content-container {
                margin-left: 5px;
                margin-top: 6px;

                .mat-vertical-content {
                    padding-left: 5px;
                }
            }

            mat-step-header {
                padding-left: 10px;
            }
        }
    }
}
