// MatCheckbox with text aligned to right
.mat-checkbox {
    &-layout {
        width: 100%;
    }

    &-inner-container {
        margin-left: 0;
    }

    &-label {
        width: 100%;
        display: flex;
    }

    &-label span.text-aligned-right {
        margin-left: auto;
    }
}
