mat-option.location-option {
    padding-left: 32px;
}

.location-info-container {
    line-height: normal;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1.1rem;
    color: #575962;
    display: flex;
    align-items: center;

    .location-info {
        display: flex;
        flex-direction: column;
        width: 80%;
        overflow: hidden;

        .location-info__poi-name {
            white-space: pre-line;
        }
    }

    .airport-code {
        display: flex;
        justify-content: flex-end;
        width: 20%;
        font-size: 1.6rem;
        color: lighten($color: #575962, $amount: 20%);
    }
}
