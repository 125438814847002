body .ui-dropdown {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0 0.5rem 0.25rem 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e5ec;
    border-radius: 4px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

body .ui-dropdown:not(.ui-state-disabled):hover {
    border-color: #9aabff;
}

.primeng-datatable-container .primeng-paging-container .ui-paginator .ui-dropdown {
    max-width: 70px;
    height: calc(1.5em + 1.3rem + 2px);
    display: inline-block;
    text-align: left;
}
