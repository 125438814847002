@import './../partials/custom-scrollbar.scss';

@include customScrollbar();

.mat-sidenav-container,
.mat-sidenav-content,
.mat-tab-body-content {
    transform: none !important;
}

.ngx-gallery-image {
    z-index: 40 !important;
}


.popoverContainer {
    max-width: 600px !important;
}
.detail-tabs {
    padding-right: 10px;
}

.img-responsive {
    min-width: 100%;
}

.booking-item__price small {
    font-size: 0.6em;
}

.bolded-element {
    font-size: 15px;
    font-weight: 600;
}

.text-smaller {
    font-size: 80%;
}

.leaflet-container {
    font-family: inherit;
}

.fare-description {
    p {
        text-align: left;
        position: relative;
        padding-left: 20px;

        &.free {
            color: #28a745;

            &::before {
                content: '\e5ca';
            }
        }

        &.message {
            &::before {
                content: '\e0c9';
            }
        }

        &.not-offered,
        &.displayed-not-offered {
            color: darken(#e0e0e0, 10%);

            &::before {
                content: '\e5cd';
            }
        }

        &.chargeable {
            color: darken(#737373, 7%);

            &::before {
                content: '\e926';
            }
        }

        &::first-letter {
            text-transform: uppercase;
        }

        &::before {
            font-family: 'Material Icons';
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

.modal-full {
    min-width: 100%;
    margin: 0;

    .modal-content {
        min-height: 100vh;
        border-radius: 0px;
    }
}

.alert-grey {
    border-color: var(--primary) !important;
    background-color: rgba(0, 0, 0, 0.03) !important;
    color: darken(#e0e0e0, 10%);
}


.bootstrap-datetimepicker-widget.dropdown-menu {
    z-index: 1001;
}

@mixin dropdownTop() {
    left: 0px !important;
    right: auto !important;
    top: auto !important;
    transform: translateY(-101%) !important;
}

.passenger-dropdown {
    @media (max-width: var(--breakpoint-md)) {
        @include dropdownTop();
    }

    @media (max-height: 860px) {
        @include dropdownTop();
    }
}

.modal-in-modal {
    height: 100vh;
    .modal-dialog {
        margin: 0 auto;
    }
}

/*@media (max-width: 575.98px) {
    .hide-title-on-mobile .ui-column-title {
        display: none !important;
    }
}*/


::-moz-selection {
    background-color: var(--primary);
    color: var(--secondary);
}
::selection {
    background-color: var(--primary);
    color: var(--secondary);
}

.custom-select:focus {
    box-shadow: unset;
}

/*.form-control.focus,
.form-control:focus {
    border-color: var(--primary) !important;
}*/

.cash-icon {
    font-size: 2.1em !important;
    vertical-align: middle;
}

/*.m-login__logo img {
    max-height: 100%;
    max-width: 100%;
}*/
popover-container.anchor-popover-to-top {
    top: 0 !important;
}

#help-popover + popover-container {
    left: calc(100% - 320px) !important;

    .popover-arrow.arrow {
        &::after,
        &::before {
            border-width: 0;
        }
    }
}


@media (max-width: 640.98px) {
    #create-edit-agency colgroup {
        & col {
            width: 100% !important;
        }
    }
}

.red-terminal-icon {
    width: 17px;
}



.dropdown-menu .dropdown-menu-dropdown {
    list-style: none;
    li a {
        padding: 0.7rem 1.2rem !important;
        padding-left: 3em !important;
    }
}



.airline--logo {
    display: block;
    max-width: 100%;

    &__default {
        display: none;
        max-width: 100%;
    }

    &__mobile {
        display: block;
    }

    @media (min-width: 768px) {
        &__default {
            display: block;
        }

        &__mobile {
            display: none;
        }
    }
}

.spot {
    height: 9px;
    width: 9px;
    border-radius: 50%;
    display: inline-block;

    &.spot-success {
        background-color: var(--success);
    }
}

.swap {
    display: none;

    @media(min-width: 576px) {
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        vertical-align: top !important;
        padding-top: 25px;
        display: flex;
        justify-content: center;
    }
}

.custom-badge {
        margin-right: 14px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 0.9em;
        margin-top: 8px;
        padding: 0.5em 0.75em !important;
        color: white !important;
        border-radius: 1.25rem !important;
        background-color: var(--light);

        .remove {
            text-decoration: none;
            color: white;
            margin-left: 10px;

            .mat-icon {
                font-size: 14px;
                height: auto;
                width: auto;
            }
        }
    }

.popover-stops {

    .popover-content{
        padding-right: 0;
        padding-left: 0;
        padding-top: 0;
    }
}

.transparent-accordion .panel-heading.card-header{
    background-color: transparent;
}

.eur_empty:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 23px;
    top: 6px;
    display: block;
    background: #d9534f;
    left: 12px;
    -webkit-transform: translate(-50%,0) rotate(45deg);
    transform: translate(-50%,0) rotate(45deg);
}

.car-search-optgroup {

    label {
        display: flex;
        align-items: center;

        &::before {
            content: 'emoji_transportation';
            font-family: 'Material Icons';
            font-size: 1.3rem;
            padding-right: 10px;
        }
    }

}

/*select.form-control:not([size]):not([multiple]) {
    height: auto!important;
}*/

/* Start NGX Bootstrap*/
tabset.nav-hidden .nav-tabs {
    display: none;
}

// remove the border from the empty cards
.card:empty {
    border: 0 !important;
}

.accordion-heading-hide .panel-heading.card-header {
    display: none!important;
}

.input-group i {
    color: unset!important;
}

.bs-datepicker-today {
    background-color: var(--cyan);
    color: var(--white);

    &.is-highlighted:not(.disabled):not(.selected) {
        background-color: var(--cyan)!important;
    }
}

//END NGX Bootstrap


.kt-portlet label {
    font-weight: 500;
}

//manda a capo l'intl tel input
.form-group .iti.iti--allow-dropdown {
    display: block;
}

.famfamfam-flags {
    display: inline-block;
}

.ui-autocomplete .ui-autocomplete-dropdown {
    height: unset,
}

//does not cause overflow of p-dropdown
.ui-dropdown {
    min-width: auto!important;
}

.hotel-autocomplete-description {
    font-size: 1.1rem;
}

.mat-autocomplete-panel .original-hotel-autocomplete .form-group .mat-autocomplete-visible {
    min-width: 350px !important;
}

bs-daterangepicker-container.dashboard-daterange-picker {
    top: 175px !important;
    @media (max-width:767.98px) {
        top: 0px!important;
        left: -70px!important;
    }
}

.flight-departure-days {
    color: white;
    background-color: #ed8323;
    border-radius: 2px;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 55%;
}