.custom-tabs {
    & > .nav-link {
        color: #6f727d !important;
        display: flex;
        align-items: center;

        .bs-remove-tab {
            font-size: .7rem;
            margin-left: .7rem;
        }
    }

    &.nav-item.show .nav-link,
    & > .nav-link.active {
        background-color: #fff !important;
        border-color: #dee2e6 #dee2e6 var(--primary) !important;
    }

    & > .nav-link {
        padding: .5rem 1rem !important;
    }
}

.tabs-without-margin {
    .nav-pills, .nav-tabs {
        margin: 0;
    }
}
