// mat-radio button

.mat-radio-button {
    &.mat-primary {
        &.mat-radio-checked .mat-radio-outer-circle {
            border-color: var(--primary) !important;
        }

        .mat-radio-inner-circle {
            background-color: var(--primary) !important;
        }
    }
}

//mat-icon-button, mat-button and mat-stroked-button
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
    color: var(--primary) !important;
}

//mat-checkbox
/* mat-checkbox:not(.mat-checkbox-disabled) {
     &.mat-primary .mat-checkbox-ripple .mat-ripple-element {
      background-color: rgb(var(--primary)) !important;
    }
}*/

.mat-checkbox-indeterminate,
.mat-checkbox-checked {
    &.mat-primary .mat-checkbox-background {
        background-color: var(--primary) !important;
    }
}


//mat-slide-toggle
.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: var(--cyan) !important;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: var(--primary) !important;
}

//toolbar
.mat-toolbar.mat-primary {
    background-color: var(--primary) !important;
}

//radio button carriage return
.mat-radio-label-content,
.mat-checkbox-label {
    white-space: pre-line;
}

//mat stepper
.mat-stepper-vertical,
.mat-stepper-horizontal {

    &.mat-stepper-transparent {
        background-color: unset !important;
    }

    .mat-step-header {
        .mat-step-icon {
            background-color: var(--primary);
            color: var(--light);

            mat-icon {
                font-weight: 600;
            }
        }

        .mat-step-icon-not-touched {
            background-color: var(--light);
            color: var(--primary);
            mat-icon {
                font-weight: 600;
                font-size: 16px;
                height: 16px;
                width: 16px;
            }
        }


    }

    &.mat-stepper-selectable {
        .mat-step-header:not(.mat-step-selected) .mat-step-icon {
            background-color: var(--light);
            color: var(--primary);

            mat-icon {
                font-weight: 600;
            }
        }
    }

    &.mat-stepper-full {

        .mat-horizontal-content-container,
        .mat-vertical-content-container {
            padding: 0;
        }
    }
}

//cdk overlay z-index
.cdk-overlay-container {
    z-index: 1052!important;
}
