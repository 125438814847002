.primeng-datatable-container {
    width: 100%;
}

.primeng-datatable-container .ui-table div.ui-chkbox-box {
    display: inline-block !important;
    position: relative !important;
    cursor: pointer !important;
    font-size: 1rem !important;
    transition: all 0.3s !important;
}

.primeng-datatable-container .ui-table div.ui-chkbox-box span {
    display: block;
    border-radius: 3px;
    background: 0 0;
    position: absolute;
    height: 18px;
    width: 18px;
    margin: -1px 0px 0px -1.5px;
}

.primeng-datatable-container .ui-table div.ui-chkbox-box span.fa-check:before {
    margin-left: -3px;
    font-size: 12px !important;
    top: -2px !important;
    position: relative;
}

.primeng-datatable-container .ui-table th {
    font-family: "Poppins", "Roboto";
    border: none !important;
    padding: 10px 0px 10px 10px !important;
    text-align: left;
    font-weight: 600 !important;
}

.primeng-datatable-container .ui-table td {
    font-family: "Poppins", "Roboto";
    border: none !important;
    padding: 8px 10px !important;
}

.primeng-datatable-container .ui-table table {
    table-layout: fixed;
}

.primeng-datatable-container .ui-table table th,
.primeng-datatable-container .ui-table table td {
    overflow-wrap: break-word;
}

.primeng-datatable-container .ui-table table td .dropdown-menu li {
    white-space: nowrap;
}

.primeng-datatable-container .ui-table table thead tr {
    vertical-align: middle !important;
}

.primeng-datatable-container .ui-table table thead tr th {
    font-size: 14px;
    font-family: Poppins;
    font-weight: 300;
    line-height: 21px;
    vertical-align: middle;
}

.primeng-datatable-container .ui-table table tbody {
    border: none !important;
}

.primeng-datatable-container .ui-table table tbody tr.ui-widget-content {
    border: none !important;
}

.primeng-datatable-container .ui-table table tbody tr td:last-child {
    border-right: 0 !important;
}

.primeng-datatable-container .primeng-paging-container {
    padding: 6px;
    border-top: 0;
}

.primeng-datatable-container .primeng-paging-container .ui-paginator {
    text-align: right;
    border: none !important;
    background-color: transparent;
}

.primeng-datatable-container .primeng-paging-container .ui-paginator span {
    padding: 0px;
}

.primeng-datatable-container .primeng-paging-container .ui-paginator span a {
    padding-top: 11px;
    cursor: pointer !important;
    margin-right: 5px !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    span
    a:last-child {
    margin-right: 0px !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    .ui-dropdown {
    border: 1px solid #d6d6d6;
    height: 35px;
    min-width: 60px;
}

.primeng-datatable-container .primeng-paging-container .ui-paginator a {
    display: inline-block;
    cursor: pointer !important;
    height: 35px;
    width: 35px;
    padding: 0;
    padding-top: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
    opacity: 1 !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    a.ui-paginator-first
    span.fa-step-backward:before {
    content: "\f100" !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    a.ui-paginator-prev
    span.fa-backward:before {
    content: "\f104" !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    a.ui-paginator-next
    span.fa-forward:before {
    content: "\f105" !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    a.ui-paginator-last
    span.fa-step-forward:before {
    content: "\f101" !important;
}

.primeng-datatable-container
    .primeng-paging-container
    .ui-paginator
    .ui-paginator-rpp-options {
    float: left;
    margin-left: 100px;
}

.primeng-datatable-container .ui-paginator .ui-paginator-page,
.primeng-datatable-container .ui-paginator .ui-paginator-pages,
.primeng-datatable-container .ui-paginator .ui-paginator-next,
.primeng-datatable-container .ui-paginator .ui-paginator-last,
.primeng-datatable-container .ui-paginator .ui-paginator-first,
.primeng-datatable-container .ui-paginator .ui-paginator-prev,
.primeng-datatable-container .ui-paginator .ui-paginator-current {
    height: 2.5em;
}

.primeng-datatable-container .total-records-count {
    float: left;
    position: relative;
    bottom: 26px;
}

.primeng-datatable-container .ui-table-footer {
    text-align: left;
    border-top: 0;
    padding: 0.75em 1.25em !important;
}

.primeng-datatable-container .ui-table-resizable {
    padding-bottom: 0px !important;
}

.primeng-datatable-container .ui-table-loading-content {
    display: none;
}

.primeng-datatable-container .ui-table-scrollable-header {
    border: 0;
}

.primeng-datatable-container .primeng-no-data {
    border: 1px solid #f4f5f8;
    padding: 10px;
    text-align: center;
}

.dropdown-menu > .dropdown-item,
.dropdown-menu > li > a {
    white-space: nowrap;
}

.primeng-datatable-container .ui-paginator div.ui-dropdown-items-wrapper {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    min-width: 65px !important;
}

.primeng-datatable-container .ui-table tr:nth-child(even).ui-state-highlight {
    background-color: #fff !important;
    color: #333 !important;
}

.primeng-datatable-container .ui-table tr:nth-child(odd).ui-state-highlight {
    background-color: #eaeaea;
    color: #333;
}

.ui-table .ui-sortable-column .ui-sortable-column-icon {
    font-size: 0.85rem;
    opacity: 0.5;
}
