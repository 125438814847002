$email-color: #FF961C;
$telegram-color: #0088cc;
$line-color: #00b900;
$whatsapp-color: #25D366;
$messenger-color: #0080FF;

.btn-email, .btn-line, .btn-messenger, .btn-telegram, .btn-whatsapp {
    color: #fff;
}

.btn-email {
    background-color: $email-color;
}

.btn-telegram {
    background-color: $telegram-color;
}

.btn-line {
    background-color: $line-color;
}

.btn-whatsapp {
    background-color: $whatsapp-color;
}

.btn-messenger {
    background-color: $messenger-color;
}

.social-share-email {
    color: $email-color !important;
}

.social-share-telegram {
    color: $telegram-color !important;
}

.social-share-whatsapp {
    color: $whatsapp-color !important;
}

.social-share-messenger {
    color: $messenger-color !important;
}
