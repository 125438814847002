.install-prompt {
    background-color: var(--light);
    color: var(--primary);
    border-top: 1px solid var(--primary);
    border-radius: 0;
    width: 100% !important;
    min-width: 100% !important;

    /* @media (min-width: 960px) {
        border: 1px solid var(--primary);
        border-bottom: 0;
        min-width: 400px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    } */
}

install-app-link:hover {
    color: var(--cyan);
}

[app-install-pwa-prompt] {
    display: flex;
    align-items: center;
}
