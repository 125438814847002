.leaflet-marker-iconlabel {
    background: #fff;
    -moz-border-radius: 7px;
    -webkit-border-radius: 7px;
    border-radius: 7px;
    -moz-box-shadow: 0 3px 10px #888;
    -webkit-box-shadow: 0 3px 14px #999;
    box-shadow: 0 3px 10px #888;
    font: 12px/1.5 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 4px 6px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.sweet-deal-label {
    background-color: var(--primary);
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    font-weight: bold;
}

.leaflet-marker-iconlabel.sweet-deal-label::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 65%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--primary) transparent transparent transparent;
}

.leaflet-marker-icon.leaflet-div-icon.leaflet-zoom-animated.leaflet-interactive {
    width: unset !important;
    height: unset !important;
    border: unset !important;
    padding-bottom: 1.5px;
}

.leaflet-popup-content-wrapper {
    border-radius: 0;
}

.leaflet-popup-content {
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.leaflet-container {
    z-index: 0;
}
