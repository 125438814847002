//tooltip-primary
.tooltip.tooltip-primary .tooltip-inner {
    background-color: var(--cyan);
    color: var(--white);
}

.tooltip.tooltip-primary.top .tooltip-arrow:before {
    border-top-color: var(--cyan)!important;
}

.tooltip.tooltip-primary.right .tooltip-arrow:before {
    border-right-color: var(--cyan)!important;
}

.tooltip.tooltip-primary.left .tooltip-arrow:before {
    border-left-color: var(--cyan)!important;
}

.tooltip.tooltip-primary.bottom .tooltip-arrow:before {
    border-bottom-color: var(--cyan)!important;
}

.tooltip.tooltip-max-500 .tooltip-inner {
    max-width: 500px !important;
}
