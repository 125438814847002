.btn-gray {
    background-color: #ebedf2 !important;
    border: 1px solid #e2e5ec!important;
    color: #595d6e!important;

    &:hover {
        background-color: darken($color: #ebedf2, $amount: 20%)!important;
    }

    &:active {
        background-color: darken($color: #ebedf2, $amount: 25%)!important;
    }
}
