.train--logo {
    display: flex;

    &__sm {
        height: 1.5rem;
    }

    &__md {
        height: 2rem;
    }

    &__xl {
        height: 2.5rem;
    }
}
