.ui-terminal {
    background-color: #000080 !important;
    height: 20em !important;
}

p-terminal, .ui-terminal-content, .ui-terminal-content-prompt, .ui-terminal-input, .ui-terminal-content pre {
    color: #ffffff !important;
    text-transform: uppercase !important;
}

.terminal {
    &--toolbar {
        height: 40px!important;
    }
}

p-terminal, .ui-terminal, .ui-terminal-content, .ui-terminal-content-prompt, .ui-terminal-input, .ui-terminal-content pre {
    font-family: 'Courier New', Courier, Roboto, monospace !important;
}

.ui-terminal-content div {
    white-space: pre;
}
