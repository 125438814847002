@import './../modules/colors.scss';
body .ui-autocomplete .ui-state-active,
body .ui-autocomplete .ui-state-highlight {
    background-color: var(--primary)!important;
    border-radius: 10rem;
    padding: .3em 1em!important;
}

body .ui-autocomplete-token {
    margin-bottom: 1.5px;
    margin-right: 2px;
    position: relative;

    div.selected-item-tooltip {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        right: 0;
        left: 0;
    }
}

body .ui-autocomplete-token-icon {
    cursor: pointer;
    top: 50% !important;
    font-size: 1.2em !important;
    right: 0.1em !important;
    z-index: 2;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
    border-color: $border-input-color !important;
    display: flex;
    align-items: center;
}

body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
    display: inline-flex;
    width: 100%;
    padding: .85rem 1.15rem !important;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    flex-wrap: wrap;
    -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

body .ui-autocomplete.ui-inputtext.ui-state-focus, body .ui-inputtext:focus {
    border: 1px solid var(--primary) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-primeng-autocomplete {
    width: 100%;
}

body .ui-autocomplete-token-label, body .ui-autocomplete-token {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

body .ui-autocomplete-token-label, body .ui-autocomplete-token span:not(.ui-autocomplete-token-icon) {
    margin-right: 10px;
}

body .custom-primeng-autocomplete.ui-autocomplete.ui-widget.ui-autocomplete-dd {
    display: flex !important;
    flex-direction: row !important;

    .ui-autocomplete-dropdown.ui-button.ui-widget {
        background-color: var(--primary) !important;
        padding: 0;
    }

}

body .ui-autocomplete .ui-autocomplete-dropdown .ui-button-text {
    padding: .85rem 1.15rem;
}


/*Input Group Autocomplete */
.input-group {
    flex-wrap: nowrap !important;
    p-autocomplete {
        width: 100%;
    }
}

@media(max-width: 575.97px) {
    .ui-autocomplete-input-group {
        flex-direction: column;
        .input-group-prepend .btn, .input-group-append .btn {
            width: 100%
        }
    }
}
